import React from "react"
import styled from "styled-components"
import Recaptcha from "react-google-recaptcha";
import { BrandColours } from "../../utils/colours";

const BodyWrapper = styled.div`
  max-width: 1000px;

  h2{
    text-align: center;
    margin-top: 1rem;
    font-weight: 700;
  }

  p{font-size: 0.8rem;}
  
  .ContentHighlights{
    max-width: 1200px;
    margin: auto;
    text-align: center;
    display: flex;
    flex-flow:column;
    h1, h2{
      color: #333333;
    }
    .carousel-indicators {
      display: none;
    }
    .carousel-control-prev-icon, .carousel-control-next-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #333333;
      background-size: 80% 80%;
      border-radius: 50%;
    }
    
  }

  label{
    margin-top: 16px;
    font-weight:700;
    margin-bottom: 0;
  }

  .ContactFromCheckBox {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }
  .ContactFromCheckBox label{
    font-weight: 300;
  }
  label.light {
    font-weight: 300;
    display: inline;
  }
  label, .newslettercheck {
    margin-bottom: 8px;
  }
  .radio-label-control {
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
  }


  .formbutton {
    text-decoration: none;
    border-radius: 4px;
    min-height: 47px;
    min-width: 200px;
    background-color: ${BrandColours.purple.base};
    border: none;
    color: ${BrandColours.white.base};
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    transition: 0.3s ease-in-out;

    &:hover{
      background-color: ${BrandColours.purple.main};
      font-weight: 900;
    }
  }
`;

const RECAPTCHA_KEY = "6LcSRC8aAAAAAGqwE4sj5-TNm5CqScknvKB8Cmhq";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class ContactUsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRecaptcha = value => {
    this.setState({ "g-recaptcha-response": value });
  };

  handleSubmit = e => {
    e.preventDefault();
      var captcha = document.querySelector('#g-recaptcha-response').value;

    const form = e.target;
    if(document.getElementById('mail').value !== document.getElementById('confmail').value){
      document.querySelector('.alertblue').style.display = 'block';
    }
          else if (captcha === undefined ||
        captcha === '' ||
        captcha === null) {
        document.querySelector('.alertred').style.display = 'block';
      }

    else {
    
      function clearform(){
            /*Clear form*/
      var clearform= document.getElementById('ContactForm');
      // var grecaptcha= document.getElementsByClassName('grecaptcha');
      clearform.reset();
      }
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
    .then(res => {
      if (res) {
      document.querySelector('.alertred').style.display = 'none';
      document.querySelector('.alertblue').style.display = 'none';
      document.querySelector('.alertgreen').style.display = 'block';
      clearform();
      }
    })
      .catch(error => alert(error));

      setTimeout(function(){
        document.querySelector('.alertgreen').style.display = 'none';
        document.querySelector('.alertred').style.display = 'none';
        document.querySelector('.alertblue').style.display = 'none';
      },3000);
    }
  };

  render() {
    return (
        <>
        <BodyWrapper>
        <h2>Contact Us</h2>
        <form autoComplete="on" id="ContactForm" name="Contact Form" method="post" data-netlify="true"
          data-netlify-recaptcha="true"
          onSubmit={this.handleSubmit}
        >
          <label htmlFor="name">Name</label>
              <div className="row margin-bottom-20">
                  <div className="col-md-12 col-centered">
                      <input placeholder="Enter name here" className="form-control" type="text" id="name" name="Name" required onChange={this.handleChange} />
                  </div>
              </div>
              <label htmlFor="mail">Email
              </label>
              <div className="row margin-bottom-20">
                  <div className="col-md-12 col-centered">
                      <input placeholder="Enter e-mail here" className="form-control" type="email" id="mail" name="Email" autoComplete="on" required onChange={this.handleChange} />
                  </div>
              </div>
              <label htmlFor="confmail">Confirm Email
              </label>
              <div className="row margin-bottom-20">
                  <div className="col-md-12 col-centered">
                      <input placeholder="Enter e-mail here" className="form-control" type="email" id="confmail" name="contact_email" autoComplete="on" required/>
                  </div>
              </div>

              <label htmlFor="Message">Message</label>
              <div className="row margin-bottom-20">
                  <div className="col-md-12 col-centered">
                      <textarea placeholder="Enter message here" rows="8" className="form-control" id="Message" name="Message" required onChange={this.handleChange}></textarea>
                  </div>
              </div>
              <p className="newslettercheck">Would you like to subscribe to our newsletter?</p>
              <div className="">
                <label className="light radio-label-control" htmlFor="newsletter-yes">Yes please!!</label>
                <input type="radio" name="newsletter_consent" id="newsletter-yes" value="Has consented to joingin mailing list" onChange={this.handleChange} />
                <label className="light radio-label-control" htmlFor="newsletter-no">No thanks!!</label>
                <input type="radio" name="newsletter_consent" id="newsletter-no" value="Has not consented to joingin mailing list" defaultChecked onChange={this.handleChange} />
              </div>
              <p>We use MailerLite as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to MailerLite for processing. <a href="https://www.mailerlite.com/legal/privacy-policy" target="_blank" rel="noreferrer noopener">Learn more about MailerLite's privacy practices here.</a></p>
              <p>You can unsubscribe at any time by clicking the link in the footer of our emails. For information about our privacy practices, please see our <a href="/privacy-policy" target="_blank">privacy policy</a>.</p>
          <Recaptcha
            ref="recaptcha"
            sitekey={RECAPTCHA_KEY}
            onChange={this.handleRecaptcha}
          />
          <div className="alertgreen col-centered">
            Thank you for contacting us!!
          </div>
          <div className="alertblue col-centered">
              Please make sure your Emails match!!
            </div>  
          <div className="alertred col-centered">
            Please Confirm you are not a robot!!
          </div>  
          <button type="submit" className="formbutton col-md-3 margin-vert-20 col-centered">Send</button>

        </form>
        </BodyWrapper>
        </>
    );
  }
}
