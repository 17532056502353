import React from "react"
import ContactForm from "../components/about-us/contactus"
import AboutUsTeam from "../components/about-us/AboutUsTeam"
import SEO from "../components/seo"
import Layout from "../components/layout";
import styled from "styled-components";

const Main = styled.main`
`;

const BodyWrapper = styled.div`
  margin: 0 auto;
  max-width: 1450px;
  height: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

const OurBox = styled.div`
h2{
  text-align : center;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  font-weight: 700;
}
p{
  font-weight: 700;
  font-size: 0.95rem;
  @media (min-width: 650px) {
  text-align : center;
  }
}

.OurTeam{
  margin-top: 3rem;
}
`;

const OurTeam = styled.div`
display: flex;
flex-flow: row wrap;
justify-content: center;
`;


class AboutUsPage extends React.Component {
  render() {

    return (
      <Main>
        <Layout location={this.props.location}>
          <SEO 
          title='About Us'
          keywords={['anxious', 'extrovert', 'blog', 'breathing', 'mental', 'health', 'help', 'young', 'youngminds', 'student', 'mind', 'minds', 'exercises', 'services', 'blogs', 'calm', 'coping']}/>
          <BodyWrapper>
            <OurBox>
              <h2>Our Story</h2>
              <p>We believe no one should feel alone in their mental health journey, so we've created a space to share our experiences and allow you to share yours.
                 Through our platform, you'll also find content about mental health services and products.<br/>Anxious Extrovert isn't just another mental health brand, we are a non-judgemental community of like-minded people.
              </p>
            </OurBox>
            {/* <OurBox>
              <h2 className="OurTeam">Our Team</h2>
              <OurTeam>
                <AboutUsTeam/>
              </OurTeam>
            </OurBox> */}
            <ContactForm/>
          </BodyWrapper>
        </Layout>
      </Main>
    )
  }
}

export default AboutUsPage;
