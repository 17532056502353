import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image";
import { BrandColours } from "../../utils/colours";

const OurTeam = styled.div`
display: flex;
flex-flow: row;
overflow: hidden;
border-radius: 20px;

.TeamImage {
  min-height: 150px;
  min-width: 150px;
  border-radius: 50%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin: 0 10px 25px;
}
.Description{
  display: flex;
  flex-flow: column;
  justify-content: center;
  color: ${BrandColours.monotone.dark};

  p:first-child {
    font-weight: 900;
  }

  p{
    font-size: 0.85rem;
    text-align:left;
    font-weight: 700;
    margin-bottom: 8px;
    color: ${BrandColours.monotone.dark};
  }
}

.PurpleBorder {
  border: 3px solid ${BrandColours.purple.main};
  box-shadow: 0 4px 10px ${BrandColours.purple.base};
}
.PurpleText p{
  color: ${BrandColours.purple.base};
}

.BlueBorder {
  border: 3px solid ${BrandColours.blue.main};
  box-shadow: 0 4px 10px ${BrandColours.blue.base};
}
.BlueText p{
  color: ${BrandColours.blue.base};
}

.GreenBorder {
  border: 3px solid ${BrandColours.green.main};
  box-shadow: 0 4px 10px ${BrandColours.green.base};
}
.GreenText p{
  color: ${BrandColours.green.base};
}

@media (min-width: 650px) {
  flex-flow: column;
  width: 200px;
  margin: 10px 20px 30px 20px;
  .TeamImage {
    margin-bottom: 20px;
  }

  .Description{
    p{
      font-size: 1rem;
      text-align: center;
    }
  }
}
`;

function AboutUsTean(){
    const data = useStaticQuery (graphql`
    query Images {
      Sarah: file(relativePath: {eq: "about-us/sarah.jpg"}) {
        id
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      },
      Gianluca: file(relativePath: {eq: "about-us/gianluca.jpg"}) {
        id
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      },
      Oliver: file(relativePath: {eq: "about-us/oliver.jpg"}) {
        id
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      },
      Samuel: file(relativePath: {eq: "about-us/samuel.jpg"}) {
        id
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }       
    `
    )      

  
    return(
        <>
              <OurTeam>
                <Img className="TeamImage PurpleBorder" fluid={data.Samuel.childImageSharp.fluid} objectPosition="Top" alt="Samuel Grantson"/>
                <div className="Description PurpleText">
                  <p>
                    Sam
                  </p>
                  <p>
                    Chief Executive Officer
                  </p>
                  <p className="Gender">He/Him</p>
                </div>
              </OurTeam>
              <OurTeam>
                <Img className="TeamImage BlueBorder" fluid={data.Oliver.childImageSharp.fluid} alt="Oliver McBurney"/>
                <div className="Description BlueText">
                  <p>
                    Ollie
                  </p>
                  <p>
                    Chief Technology Officer
                  </p>
                  <p className="Gender">He/Him</p>
                </div>
              </OurTeam>
              <OurTeam>
                <Img className="TeamImage GreenBorder" fluid={data.Sarah.childImageSharp.fluid} alt="Sarah Nobbs"/>
                <div className="Description GreenText">
                  <p>
                    Sarah
                  </p>
                  <p>
                    Cheif Marketing Officer
                  </p>
                  <p className="Gender">She/Her</p>
                </div>
              </OurTeam>
        </>

    )
}

export default AboutUsTean